<template>
  <BCard>
    <div class="text-black text-2xl font-semibold mb-2">Assignment</div>
    <BCol class="p-0 d-flex">
      <div class="d-flex rounded-lg align-items-center border search-bar">
        <span
          class="k-search-normal-1 h-100 font-bold text-10 pt-[7px] align-middle mx-[5px]"
        />
        <BFormInput
          v-model="keyword"
          placeholder="Cari Nama"
          @input="handleSearch()"
          class="border-0"
        />
      </div>
      <BButton
        tag="router-link"
        :to="{
          name: $route.meta.routeCreate,
        }"
        variant="primary"
        class="ml-auto px-3"
      >
        <strong>Tambah</strong>
      </BButton>
    </BCol>

    <BOverlay
      id="scroll"
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <div id="table" style="height: calc(100vh - 275px); overflow-y: scroll;" class="mt-[24px]" @scroll="handleScroll">
        <BTable
          :items="items"
          :fields="fields"
          empty-text="Tidak ada data yang ditampilkan."
          responsive
          show-empty
        >
          <template #cell(action)="data">
            <div class="flex justify-end items-center">
              <BButton
                class="btn-icon mr-50"
                size="sm"
                variant="flat-primary"
                :to="{
                  name: $route.meta.routeShow,
                  params: { id: data.item.id }
                }"
                tag="router-link"
              >
                <div
                  class="text--blue"
                >Lihat detail</div>
              </BButton>
            </div>
          </template>
        </BTable>
      </div>
    </BOverlay>
  </BCard>
</template>

<script>
import { komtimAxiosIns } from '@/libs/axios'
import { alertError } from '@toast'
import { tableBiodata } from './config'

export default {
  data() {
    return {
      loading: false,
      items: [],
      limit: 20,
      offset: 0,
      lastData: false,
      keyword: '',
      fields: tableBiodata,
      idItem: 0,
      filterSelected: [],
      listDivisi: [],
      alertError,
    }
  },
  created() {
    this.$root.$on('bv::dropdown::show', bvEvent => {
      if (bvEvent.componentId === 'dropdown-2') {
        this.isDropdown2Visible = true
      }
    })
    this.$root.$on('bv::dropdown::hide', bvEvent => {
      if (bvEvent.componentId === 'dropdown-2') {
        this.isDropdown2Visible = false
      }
      if (this.isDropdown2Visible) {
        bvEvent.preventDefault()
      }
    })
  },
  mounted() {
    this.getListData()
  },
  methods: {
    handleScroll() {
      const table = document.getElementById('table')
      if (
        table.scrollTop >= table.scrollHeight - table.offsetHeight - 5
        && !this.loading
        && !this.lastData
      ) {
        this.getNextData()
      }
    },
    async getListData() {
      this.loading = true
      this.offset = 0
      const params = `offset=${this.offset}&limit=${this.limit}&keyword=${encodeURIComponent(this.keyword)}`
      const url = `v1/partner_assignments?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.items = data
          this.offset = data.length

          if (data.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    async getListDivisi() {
      const params = 'status=true'
      const url = `v1/divisions?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.listDivisi = data
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    async getNextData() {
      this.loading = true
      if (!this.lastData) {
        const params = `offset=${this.offset}&limit=${this.limit}&keyword=${encodeURIComponent(this.keyword)}`
        const url = `v1/partner_assignments?${params}`

        await komtimAxiosIns
          .get(url)
          .then(res => {
            const { data } = res.data
            this.items.push(...data)
            this.offset += data.length
            this.loading = false
            if (data.length < this.limit) {
              this.lastData = true
            }
          })
          .catch(err => {
            this.alertError(err)
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    handleSearch: _.debounce(function search() {
      this.getListData()
    }, 1000),
    selectItem(id) {
      this.idItem = id
    },
    resetFilter() {
      this.filterSelected = []
      this.getListData()
    },
  },
}
</script>
